/***************************************************/
// Capa de Servicios para el modulo de Laboratorios.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };

  constructor() {
    this.#baseUrl = store.getters.getBaseURL;
  }

  async reload() {
    this.header = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };
  }

  async getRecordSlu(slu) {
    try {
      let response = await axios.get(`${this.#baseUrl}/slu/laboratories/${slu}`);
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getAllRecords() {
    try {
      let response = await axios.get(this.#baseUrl + "/laboratories");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async uploadDocument(id, oficial_identity, oficial_identity2, professional_cedula, doc_repssabi_coepris, certification_or_accreditation, ext_photo, logo_entity, ret_secture){
    await this.reload();
    const formData = new FormData();
    formData.append("id", id);
    formData.append("oficial_identity", oficial_identity);
    formData.append("oficial_identity2", oficial_identity2);
    formData.append("professional_cedula", professional_cedula);
    formData.append("doc_repssabi_coepris", doc_repssabi_coepris);
    formData.append("certification_or_accreditation", certification_or_accreditation);
    formData.append("ext_photo", ext_photo);
    formData.append("logo_entity", logo_entity);
    formData.append("ret_secture", ret_secture);
    return axios.post(`${this.#baseUrl}/add-documents/laboratories`, formData,{
          headers: this.header
        });
  }

  async getData() {
    try {
      let response = await axios.get(this.#baseUrl + "/view/laboratories");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getViewList(active=false, city='', municipality='') {
    try {
      let response = await axios.get(this.#baseUrl + "/view-list/laboratories", {
        params: {
          active: active,
          city: city,
          municipality: municipality
        }
      });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getRecord(id) {
    try {
      let response = await axios.get(`${this.#baseUrl}/laboratories/${id}`);
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async addRecord(formData) {
    await this.reload();
    try {
      return axios.post(
        this.#baseUrl + "/laboratories",
        {
          name: formData.name,
          description: formData.description,
          description_english: formData.description_english,
          establishment_type: formData.establishment_type,
          email: formData.email,
          address: formData.address,
          phone: formData.phone,
          web_page: formData.web_page,
          map_lat: formData.map_lat,
          map_lng: formData.map_lng,
          facebook_profile: formData.facebook_profile,
          instagram_profile: formData.instagram_profile,
          servicehours: formData.servicehours,
          vencimiento_fecha: formData.vencimiento_fecha,
          coepris_qr_link: formData.coepris_qr_link,
          active: formData.active,
          typeEntity: formData.typeEntity,
          coparmex: formData.coparmex,
          state: formData.state,
          comment: formData.comment,
          comment_ceetsb: formData.comment_ceetsb,
          comment_coparmex: formData.comment_coparmex,
          comment_coepris: formData.comment_coepris,
          state_ceetsb: formData.state_ceetsb,
          state_coparmex: formData.state_coparmex,
          state_coepris: formData.state_coepris,
          videos: formData.videos,
          property_name: formData.property_name,
          first_last_name: formData.first_last_name,
          second_lastname: formData.second_lastname,
          gender: formData.gender,
          other_option: formData.other_option,
          rfc: formData.rfc,
          whatsapp: formData.whatsapp,
          monday: formData.monday,
          tuesday: formData.tuesday,
          wednesday: formData.wednesday,
          thursday: formData.thursday,
          friday: formData.friday,
          saturday: formData.saturday,
          sunday: formData.sunday,
          comercial_rotate: formData.comercial_rotate,
          other_option_form: formData.other_option_form,
          cian_key: formData.cian_key,
          attention_medic: formData.attention_medic,
          bibliographic: formData.bibliographic,
          tiktok_profile: formData.tiktok_profile,
          english: formData.english,
          english_level: formData.english_level,
          professional_organism: formData.professional_organism,
          integral_services: formData.integral_services,
          hotel: formData.hotel,
          transportation: formData.transportation,
          restaurant: formData.restaurant,
          touroperator: formData.touroperator,
          house: formData.house,
          house1: formData.house1,
          broker: formData.broker,
          not: formData.not,
          not_interest: formData.not_interest,
          comercial_name: formData.comercial_name,
          is_member: formData.is_member,
          others_name: formData.others_name,
          is_accredit: formData.is_accredit,
          JCI: formData.JCI,
          CNSG: formData.CNSG,
          GHA: formData.GHA,
          CEHC: formData.CEHC,
          SRC: formData.SRC,
          SRCCE: formData.SRCCE,
          SRCMS: formData.SRCMS,
          SRCCS: formData.SRCCS,
          CAMN: formData.CAMN,
          STSM: formData.STSM,
          STI: formData.STI,
          ISO9001: formData.ISO9001,
          ISO27001: formData.ISO27001,
          ISO45001: formData.ISO45001,
          ISO13485: formData.ISO13485,
          other_option_select: formData.other_option_select,
          repssabi_coepris: formData.repssabi_coepris,
          folio_repssabi_coepris: formData.folio_repssabi_coepris,
          date_repssabi_coepris: formData.date_repssabi_coepris,
          prevented: formData.prevented,
          province: formData.province,
          municipality: formData.municipality,
          trial: formData.trial,
        },
        {
          headers: this.header
        }
      );
    } catch (error) {
      return this.handleError(error);
    }
  }

  // Pushes posts to the server when called.
  async updateRecord(formData) {
    try {
      await this.reload();
      return axios.put(
        `${this.#baseUrl}/laboratories/${formData.id}`,
        {
          id: formData.id,
          name: formData.name,
          description: formData.description,
          description_english: formData.description_english,
          establishment_type: formData.establishment_type,
          email: formData.email,
          address: formData.address,
          phone: formData.phone,
          web_page: formData.web_page,
          map_lat: formData.map_lat,
          map_lng: formData.map_lng,
          facebook_profile: formData.facebook_profile,
          instagram_profile: formData.instagram_profile,
          servicehours: formData.servicehours,
          vencimiento_fecha: formData.vencimiento_fecha,
          coepris_qr_link: formData.coepris_qr_link,
          active: formData.active,
          typeEntity: formData.typeEntity,
          coparmex: formData.coparmex,
          state: formData.state,
          comment: formData.comment,
          comment_ceetsb: formData.comment_ceetsb,
          comment_coparmex: formData.comment_coparmex,
          comment_coepris: formData.comment_coepris,
          state_ceetsb: formData.state_ceetsb,
          state_coparmex: formData.state_coparmex,
          state_coepris: formData.state_coepris,
          videos: formData.videos,
          property_name: formData.property_name,
          first_last_name: formData.first_last_name,
          second_lastname: formData.second_lastname,
          gender: formData.gender,
          other_option: formData.other_option,
          rfc: formData.rfc,
          whatsapp: formData.whatsapp,
          monday: formData.monday,
          tuesday: formData.tuesday,
          wednesday: formData.wednesday,
          thursday: formData.thursday,
          friday: formData.friday,
          saturday: formData.saturday,
          sunday: formData.sunday,
          comercial_rotate: formData.comercial_rotate,
          other_option_form: formData.other_option_form,
          cian_key: formData.cian_key,
          attention_medic: formData.attention_medic,
          bibliographic: formData.bibliographic,
          tiktok_profile: formData.tiktok_profile,
          english: formData.english,
          english_level: formData.english_level,
          professional_organism: formData.professional_organism,
          integral_services: formData.integral_services,
          hotel: formData.hotel,
          transportation: formData.transportation,
          restaurant: formData.restaurant,
          touroperator: formData.touroperator,
          house: formData.house,
          house1: formData.house1,
          broker: formData.broker,
          not: formData.not,
          not_interest: formData.not_interest,
          comercial_name: formData.comercial_name,
          is_member: formData.is_member,
          others_name: formData.others_name,
          is_accredit: formData.is_accredit,
          JCI: formData.JCI,
          CNSG: formData.CNSG,
          GHA: formData.GHA,
          CEHC: formData.CEHC,
          SRC: formData.SRC,
          SRCCE: formData.SRCCE,
          SRCMS: formData.SRCMS,
          SRCCS: formData.SRCCS,
          CAMN: formData.CAMN,
          STSM: formData.STSM,
          STI: formData.STI,
          ISO9001: formData.ISO9001,
          ISO27001: formData.ISO27001,
          ISO45001: formData.ISO45001,
          ISO13485: formData.ISO13485,
          other_option_select: formData.other_option_select,
          repssabi_coepris: formData.repssabi_coepris,
          folio_repssabi_coepris: formData.folio_repssabi_coepris,
          date_repssabi_coepris: formData.date_repssabi_coepris,
          prevented: formData.prevented,
          province: formData.province,
          municipality: formData.municipality,
          trial: formData.trial,
        },
        {
          headers: this.header
        }
      );
    } catch (error) {
      return this.handleError(error);
    }
  }

  async deleteRecord(id) {
    try {
      await this.reload();
      let response = await axios.delete(`${this.#baseUrl}/laboratories/${id}`, {
          headers: this.header
        });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async uploadImage(laboratory_id, picture_id, imageFile) {
    if (imageFile) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("laboratory_id", laboratory_id);
        formData.append("picture_id", picture_id);
        formData.append("file", imageFile);
        let response = await axios.post(
          this.#baseUrl + "/upload-image/laboratories",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async deleteImage(laboratory_id, picture_id) {
    if (picture_id) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("laboratory_id", laboratory_id);
        formData.append("picture_id", picture_id);
        let response = await axios.post(
          this.#baseUrl + "/delete-image/laboratories",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  //  qr

  async deleteQr(laboratory_id, coepris_qr) {
    if (coepris_qr) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("laboratory_id", laboratory_id);
        formData.append("coepris_qr", coepris_qr);
        let response = await axios.post(
          this.#baseUrl + "/delete-qr/laboratories",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async uploadQr(laboratory_id, qr_code_id, imageFile, isQr = true) {
    if (imageFile) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("laboratory_id", laboratory_id);
        formData.append("qr_code_id", qr_code_id);
        formData.append("file", imageFile);
        formData.append("isQr", isQr);
        let response = await axios.post(
          this.#baseUrl + "/upload-image/laboratories",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  ////////////////////////////////////////////////

  async sendEmail(profile_id, email) {
    try {
      let response = await axios.get(
        `${
          this.#baseUrl
        }/send-email/laboratories?id=${profile_id}&email=${email}`
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      let result;
      result = this.handleError(error);
      return result;
    }
  }

  handleError(error) {
    let valueError;
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      valueError = error.response.data.messaje;
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      valueError = error.request.data;
    } else {
      // Something happened in setting up the request and triggered an Error
      valueError = error.message;
    }
    // console.log(error.config);
    return valueError;
  }
})();
